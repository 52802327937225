import React, { Component } from 'react';
// @ts-ignore
import Hero from '../assets/hero-sm.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faUpRightFromSquare';

class App extends Component {
  render() {
    return (
      <div className="vertical-center">
        <div className="wrapper container">
          <div className="body d-inline-block">
            <div className="hero">
              <img src={Hero} title="Oh Great One" />

              <blockquote className="blockquote mb-0">
                <p><em>Tantric Llama</em></p>
                <footer className="blockquote-footer">Silly name, serious business</footer>
              </blockquote>
            </div>

            <div className="links">
              <a href="https://imtwelve.com" target="_blank" title="I'm Twelve">
                <div><em className="site">I'm Twelve</em> - Slay game key prices!</div>
                <div className="icon"><FontAwesomeIcon icon={faUpRightFromSquare} /></div>
              </a>

              <a href="https://foothillssoap.com.au" target="_blank" title="Foothills Soap">
                <div><em className="site">Foothills Soap</em> - Soap... handmade... with love!</div>
                <div className="icon"><FontAwesomeIcon icon={faUpRightFromSquare} /></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;